"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.throttledDispatch = exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _i18n = _interopRequireDefault(require("@/i18n"));
var _auth = require("@/utils/auth");
var _errorParse = require("@/utils/error-parse");
var _lodash = require("lodash");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const throttledDispatch = (0, _lodash.throttle)(refresh_token => {
  return _store.default.dispatch('user/login', {
    refresh_token
  });
}, 8000);
exports.throttledDispatch = throttledDispatch;
const getNetworkRTT = function () {
  var networkRTT = '-';
  if (window.PerformanceNavigationTiming) {
    // Get the performance entry for the current navigation
    var performanceEntries = performance.getEntriesByType('resource').slice().reverse();
    for (const performanceEntry of performanceEntries) {
      if (performanceEntry.initiatorType === 'fetch' || performanceEntry.initiatorType === 'xmlhttprequest') {
        networkRTT = performanceEntry.responseEnd - performanceEntry.startTime;
        break;
      }
    }
  } else if (performance.timing) {
    var startTime = performance.timing.requestStart;
    var responseEnd = performance.timing.responseEnd;
    networkRTT = responseEnd - startTime;
  }
  return networkRTT;
};

// create an axios instance
const service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
});

// request interceptor
service.interceptors.request.use(config => {
  // do something before request is sent

  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['X-Token'] = getToken()
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)();
  }
  if (_i18n.default.locale) {
    config.headers['X-localization'] = _i18n.default.locale;
  }
  if (config.responseType === 'blob') {
    config.timeout = 0;
  }
  return config;
}, error => {
  // do something with request error
  console.info(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(response => {
  /**
           * If you want to get http information such as headers or status
           * Please return  response => response
           */

  /**
           * Determine the request status by custom code
           * Here is just an example
           * You can also judge the status by HTTP Status Code
           */
  const res = response.data;

  // console.info({ response })
  if (response.headers && response.headers['warning']) {
    let warning = response.headers['warning'].match(/(?:[^\s"]+|"[^"]*")+/g);
    if (!warning[2]) {
      warning = response.headers['warning'];
    } else {
      warning = JSON.parse(warning[2]);
    }
    if (typeof warning !== 'object') {
      (0, _elementUi.Message)({
        message: warning,
        type: 'warning',
        duration: 5 * 1000
      });
    }
  }

  // if the custom code is not 20000, it is judged as an error.
  // if (res.code !== 20000) {
  // Check with status code instead
  if (response.status !== 200 && response.status !== 201) {
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    if (response.status === 401) {
      console.info(response.config);
      let dataParsed = {};
      if (response.config) {
        dataParsed = JSON.parse(response.config.data);
      }
      if (response.config && dataParsed && dataParsed.grant_type === 'refresh_token') {
        _elementUi.MessageBox.alert('You have been logged out, please log in again', 'Confirm logout', {
          confirmButtonText: 'Ok'
        }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err)).finally(() => {
          _store.default.dispatch('user/logout').then(() => {
            location.reload();
          });
        });
      } else {
        // to re-login
        if (_store.default.getters.refresh_token) {
          console.info('throttle reloggin from', response.config.url);
          const relogin = throttledDispatch(_store.default.getters.refresh_token);
          if (relogin) {
            return relogin.then(() => {
              const config = {
                ...response.config,
                headers: {
                  ...response.config.headers,
                  Authorization: 'Bearer ' + (0, _auth.getToken)()
                }
              };
              return service(config);
            }).catch(err => {
              return Promise.reject(new Error(err.message || 'Session Timeout'));
            });
          } else {
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const config = {
                  ...response.config,
                  headers: {
                    ...response.config.headers,
                    Authorization: 'Bearer ' + (0, _auth.getToken)()
                  }
                };
                service(config).then(resolve).catch(reject);
              }, 980);
            });
          }
        }
        _elementUi.MessageBox.alert('You have been logged out, please log in again', 'Confirm logout', {
          confirmButtonText: 'Ok'
        }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err)).finally(() => {
          _store.default.dispatch('user/logout').then(() => {
            location.reload();
          });
        });
        return;
      }
    } else if (response.status === 400 && response.config.url === '/api/v1/oauth/token' && response.data && JSON.parse(response.data).grant_type === 'refresh_token') {
      _elementUi.MessageBox.alert('You have been logged out, please log in again', 'Confirm logout', {
        confirmButtonText: 'Ok'
      }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err)).finally(() => {
        _store.default.dispatch('user/logout').then(() => {
          location.reload();
        });
      });
    } else if (response.status > 299) {
      (0, _elementUi.Message)({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, error => {
  let ret = {};

  // console.info({ error })
  if (error.response && error.response.data) {
    ret = error.response.data;
  }
  if (error.response) {
    console.info('come in', error.response.status, error.config.url, error.config.method, error.code, {
      ...error.config.headers
    }, error.config.data ? typeof error.config.data === 'string' ? error.config.data : {
      ...error.config.data
    } : {
      data: {
        ...error.config
      }
    }, error.message, error.config.params ? {
      ...error.config.params
    } : {
      params: null
    }, error.response.data);
    if (error.response.status === 429 && error.config && error.config.method === 'get') {
      if (!/(user|token|auth)/.test(error.config.url)) {
        console.error(((0, _errorParse.parseError)(error.response.data) || 'Too Many request, please retry in a minute') + '\n' + error.config.url);
      }
      if (!error.config.silentAlert) {
        (0, _elementUi.Message)({
          message: (0, _errorParse.parseError)(error.response.data) || 'Too Many request, please retry in a minute',
          type: 'error',
          duration: 5 * 1000
        });
      }
    } else if (error.response.status === 400 && error.config && error.config.method === 'get') {
      if (!error.config.silentAlert) {
        (0, _elementUi.Message)({
          dangerouslyUseHTMLString: true,
          message: (0, _errorParse.parseError)(error.response.data) || 'Request Fail',
          type: 'error',
          duration: 5 * 1000
        });
      }
      console.info(error.response.data);
    } else if (error.response.status === 400 && error.config.url.indexOf('/api/v1/oauth/token') !== -1 && error.config && error.config.method === 'post') {
      // 400 POST /api/v1/oauth/token
      const params = JSON.parse(error.config.data);
      if (params.grant_type !== 'password') {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            if (_store.default.getters.token) {
              resolve(service(error.config));
            }
            reject(error);
          }, 220);
        });
      }
    } else if (error.response.status === 401) {
      if (!ret.message) {
        ret.message = 'Please Login';
      }
      console.info(error.config);
      let dataParsed = null;
      if (error.config && error.config.data) {
        try {
          dataParsed = JSON.parse(error.config.data);
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
      if (dataParsed && dataParsed.grant_type === 'refresh_token') {
        return Promise.reject(ret);
      } else {
        console.info('caught 401');

        // to re-login
        if (_store.default.getters.refresh_token) {
          // refresh token not updated
          console.info('throttle reloggin from', error.config.url);
          const relogin = throttledDispatch(_store.default.getters.refresh_token);
          if (relogin) {
            console.info('caught got refresh_token: relogin');
            return relogin.then(() => {
              const config = {
                ...error.config,
                headers: {
                  ...error.config.headers,
                  Authorization: 'Bearer ' + (0, _auth.getToken)()
                }
              };
              return service(config);
            }).catch(err => {
              return Promise.reject(err);
            });
          } else {
            console.info('caught got refresh_token: throttle, then retry');
            return new Promise((resolve, reject) => {
              setTimeout(() => {
                const config = {
                  ...error.config,
                  headers: {
                    ...error.config.headers,
                    Authorization: 'Bearer ' + (0, _auth.getToken)()
                  }
                };
                service(config).then(resolve).catch(reject);
              }, 980);
            });
          }
        } else {
          console.info('caught empty refresh_token', _store.default.getters.refresh_token);
          return _store.default.dispatch('user/resetToken').then(() => {
            _elementUi.MessageBox.alert('Login Expired, please relogin', 'Request Fail', {
              confirmButtonText: 'Ok'
            }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err)).finally(() => {
              location.reload();
            });
            return Promise.reject('Login Expired, please relogin');
          });
        }
      }
    } else if (error.response.status === 403 && !ret.message) {
      if (error.response.config.responseType === 'blob' && error.response.headers && error.response.headers['content-type'] === 'application/json') {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error.response.data));
          };
          reader.onerror = () => {
            reject(error);
          };
          reader.readAsText(error.response.data);
        });
      }
      ret.message = 'Unauthorized';
    } else if (error.response.status === 404 && !ret.message) {
      if (error.response.config.responseType === 'blob' && error.response.headers && error.response.headers['content-type'] === 'application/json') {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error.response.data));
          };
          reader.onerror = () => {
            reject(error);
          };
          reader.readAsText(error.response.data);
        });
      }
      ret.message = 'Not Found';
    } else if (error.response.status === 0 && _axios.default.isAxiosError(error)) {
      // /api/v1/users/me
      if (navigator.onLine !== undefined && !navigator.onLine) {
        return Promise.resolve(error.response.status);
      } else if (error.code === 'ERR_NETWORK') {
        if (error.config.responseType === 'blob') {
          console.warn('come to axios 3, download might be blocked by browser', error.config.url, error.code, error.message, 'RTT: ' + (navigator.connection ? navigator.connection.rtt : '-'), 'networkRTT: ' + getNetworkRTT(), 'isOffline: ' + !navigator.onLine, error.response ? error.response : null);
          return Promise.resolve('download might be blocked by browser');
        } else {
          console.error('come to axios 3', error.config.url, error.code, error.message, 'RTT: ' + (navigator.connection ? navigator.connection.rtt : '-'), 'networkRTT: ' + getNetworkRTT(), 'isOffline: ' + !navigator.onLine, error.response ? error.response : null);
        }
      }
      if (navigator.onLine !== undefined && navigator.onLine) {
        _elementUi.MessageBox.alert(error.code === 'ECONNABORTED' ? 'Connection Timeout' : error.message, 'Request Fail', {
          confirmButtonText: 'Ok'
        }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
      }
      return Promise.reject(error);
    }
  } else if (_axios.default.isAxiosError(error)) {
    // /api/v1/users/me
    // ret = error
    if (error.code === 'ERR_CANCELED') {
      return Promise.resolve();
    }
    // ECONNABORTED on user/me
    if (error.code === 'ECONNABORTED' && error.config.url.indexOf('/api/v1/users/me') !== -1) {
      return Promise.reject(false);
    }
    if (navigator.onLine !== undefined && navigator.onLine) {
      console.error('come to axios 2', error.config.url, error.code, error.message, 'RTT: ' + (navigator.connection ? navigator.connection.rtt : '-'), 'networkRTT: ' + getNetworkRTT(), 'isOffline: ' + !navigator.onLine, error.response ? error.response : null);
      if (!error.config.silentAlert) {
        _elementUi.MessageBox.alert(error.code === 'ECONNABORTED' ? 'Connection Timeout' : error.message, 'Request Fail', {
          confirmButtonText: 'Ok'
        }).catch(err => err === 'cancel' ? Promise.resolve('cancelled') : Promise.reject(err));
        return Promise.reject(error);
      }
    } else if (navigator.onLine !== undefined && !navigator.onLine) {
      return Promise.resolve(error.response.status);
    }
  }
  if (error.response && error.response.headers && error.response.headers['content-type'] === 'application/json') {
    ret.headers = error.response.headers;
    ret.status = error.response.status;
  }
  if (error.response && error.response.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;