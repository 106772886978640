"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _user = require("@/api/user");
var _auth = require("@/utils/auth");
var _router = require("@/router");
const getDefaultState = () => {
  return {
    token: (0, _auth.getToken)(),
    refresh_token: (0, _auth.getRefreshToken)(),
    name: '',
    avatar: '',
    id: '',
    uuid: '',
    roles: [],
    tenant: null
  };
};
const state = getDefaultState();
const mutations = {
  RESET_STATE: state => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_REFRESH_TOKEN: (state, refresh_token) => {
    state.refresh_token = refresh_token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_TENANT: (state, tenant) => {
    state.tenant = tenant;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_UUID: (state, uuid) => {
    state.uuid = uuid;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_STORES: (state, stores) => {
    state.stores = stores;
  }
};
const actions = {
  // user login
  login({
    commit
  }, userInfo) {
    if (userInfo.refresh_token) {
      return new Promise((resolve, reject) => {
        (0, _user.relogin)(userInfo.refresh_token).then(response => {
          if (response) {
            // const { data } = response
            commit('SET_TOKEN', response.access_token);
            (0, _auth.setToken)(response.access_token);
            commit('SET_REFRESH_TOKEN', response.refresh_token);
            (0, _auth.setRefreshToken)(response.refresh_token);
            return resolve(response);
          } else {
            (0, _auth.removeRefreshToken)(); // @TODO inspect resetToken
            commit('RESET_STATE');
            return reject('empty response', response);
          }
        }).catch(error => {
          (0, _auth.removeRefreshToken)(); // @TODO inspect resetToken
          commit('RESET_STATE');
          return reject(error);
        });
      });
    }
    const {
      username,
      password
    } = userInfo;
    return new Promise((resolve, reject) => {
      (0, _user.login)({
        username: username.trim(),
        password: password
      }).then(response => {
        if (response) {
          // const { data } = response
          commit('SET_TOKEN', response.access_token);
          (0, _auth.setToken)(response.access_token);
          return resolve(response);
        } else {
          return reject(new Error('Authentication Fail, Connection Failure'));
        }
      }).catch(error => {
        return reject(error);
      });
    });
  },
  remember({
    commit
  }, refresh_token) {
    return new Promise(resolve => {
      commit('SET_REFRESH_TOKEN', refresh_token);
      (0, _auth.setRefreshToken)(refresh_token);
      resolve();
    });
  },
  // get user info
  getInfo({
    commit,
    state
  }) {
    return new Promise((resolve, reject) => {
      (0, _user.getInfo)( /* state.token */).then(response => {
        if (response) {
          // const { data } = response
          const data = response;

          // if (!data) {
          //   reject('Verification failed, please Login again.')
          // }

          // const { roles, name, avatar } = data
          const {
            roles,
            name,
            uuid,
            id,
            stores,
            tenant
          } = data.data;

          // roles must be a non-empty array
          // if (!roles || roles.length <= 0) {
          //   reject('getInfo: roles must be a non-null array!')
          // }
          // permissions.push('basic')
          commit('SET_ROLES', roles);
          commit('SET_NAME', name);
          commit('SET_UUID', uuid);
          commit('SET_ID', id);
          commit('SET_STORES', stores);
          commit('SET_TENANT', tenant);
          // commit('SET_AVATAR', avatar)
          data.data.permissions = roles.map(role => role.name);
          data.data.tenant = tenant;
          resolve(data.data);
        } else {
          reject('session reset');
        }
      }).catch(error => {
        reject(error);
      });
    });
  },
  // user logout
  logout({
    commit,
    state
  }) {
    return new Promise((resolve /* , reject */) => {
      (0, _user.logout)(state.token).then(() => {}).catch(() => {
        // reject(error)
      }).finally(() => {
        (0, _auth.removeToken)(); // must remove  token  first
        (0, _auth.removeRefreshToken)(); // @TODO inspect resetToken
        (0, _router.resetRouter)();
        commit('RESET_STATE');
        resolve();
      });
    });
  },
  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      (0, _auth.removeToken)(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  },
  // remove token
  removeRefreshToken({
    commit
  }) {
    return new Promise(resolve => {
      console.info('removeRefreshToken');
      (0, _auth.removeRefreshToken)(); // must remove  token  first
      commit('RESET_STATE');
      resolve();
    });
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;