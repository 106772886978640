"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseError = parseError;
const element = document.createElement('div');
function htmlEncode(str) {
  element.innerText = str;
  return element.innerHTML;
}
function parseError(error) {
  let message = '';
  if (error.data) {
    if (typeof error.data === 'object') {
      for (const key in error.data) {
        if (message) {
          message += '<br>';
        }
        message += htmlEncode(key) + ': ' + htmlEncode(error.data[key]);
      }
    }
  }
  if (!message) {
    message = 'Unknown Error';
    if (error.message) {
      message = error.message;
    }
  }
  return message;
}