"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const isStandalone = () => {
  let isPWAinBrowser = true;
  // replace standalone with fullscreen or minimal-ui according to your manifest
  if (window.matchMedia && matchMedia('(display-mode: standalone)').matches) {
    // Android and iOS 11.3+
    isPWAinBrowser = false;
  } else if ('standalone' in navigator) {
    // useful for iOS < 11.3
    isPWAinBrowser = !navigator.standalone;
  }
  return !isPWAinBrowser;
};
const state = {
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  isStandalone: isStandalone()
};
const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    _jsCookie.default.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  }
};
const actions = {
  toggleSideBar({
    commit
  }) {
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar({
    commit
  }, {
    withoutAnimation
  }) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice({
    commit
  }, device) {
    commit('TOGGLE_DEVICE', device);
  }
};
var _default = {
  namespaced: true,
  state,
  mutations,
  actions
};
exports.default = _default;