"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _i18n = _interopRequireDefault(require("@/i18n"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
_vue.default.use(_vueRouter.default);

/* Layout */

// import EmptyLayout from '@/layout/empty'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
const constantRoutes = [{
  path: '/login',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/login/index'))),
  hidden: true
}, {
  path: '/forget-password',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/forgetPassword/index'))),
  hidden: true
}, {
  path: '/reset-password',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/resetPassword/index'))),
  hidden: true
}, {
  path: '/404',
  component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/404'))),
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
exports.constantRoutes = constantRoutes;
const asyncRoutes = [{
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  name: 'route.Dashboards',
  meta: {
    // roles: ['Admin', 'Warehouse Manager', 'Operation Manager']
  },
  children: [{
    path: '/dashboard',
    name: _i18n.default.t('route.Dashboard'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/dashboard/index'))),
    meta: {
      title: 'Dashboard',
      icon: 'dashboard'
    }
  }, {
    path: '/changePassword',
    name: _i18n.default.t('route.ChangePassword'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/changePassword/index'))),
    meta: {
      title: 'ChangePassword',
      icon: 'dashboard'
    },
    hidden: true
  }]
},
// labour
{
  path: '/labour',
  component: _layout.default,
  name: 'Labour',
  redirect: 'noRedirect',
  meta: {
    title: 'Labour',
    icon: 'user',
    roles: ['Admin', 'Operation Manager', 'Store Manager']
  },
  children: [
  // user management
  {
    path: '/users',
    name: _i18n.default.t('route.Users'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/users/index'))),
    meta: {
      title: 'Users',
      icon: 'user',
      roles: ['Admin', 'Operation Manager', 'Store Manager']
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditUser'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/users/edit'))),
      meta: {
        title: 'EditUser',
        icon: 'education'
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddUser'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/users/edit'))),
      meta: {
        title: 'AddUser',
        icon: 'education'
      },
      hidden: true
    }, {
      path: ':id/leaves',
      name: 'Leave Type User',
      // i18n.t('route.AddUser'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/users/leaves'))),
      meta: {
        title: 'LeaveType',
        // i18n.t('route.AddUser'),
        icon: 'education'
      },
      hidden: true
    }, {
      path: ':user_id/leaves/summary',
      name: 'Leave Summary',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/summary'))),
      meta: {
        title: 'LeaveSummary',
        icon: 'chart',
        roles: ['Admin', 'Operation Manager', 'Store Manager']
      },
      hidden: true,
      children: [{
        path: '/users/:user_id/leaves/apply/add',
        name: 'Leave Add',
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/apply'))),
        meta: {
          title: 'LeaveAdd',
          // i18n.t('route.LabourCost'),
          icon: 'chart'
        },
        hidden: true
      }, {
        path: ':id',
        name: 'Leave History',
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/history'))),
        meta: {
          title: 'LeaveHistory',
          icon: 'chart'
        },
        hidden: true,
        children: [{
          path: '/users/:user_id/leaves/apply/edit/:id',
          name: 'Leave Edit',
          component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/apply'))),
          meta: {
            title: 'LeaveEdit',
            // i18n.t('route.LabourCost'),
            icon: 'chart'
          },
          hidden: true
        }]
      }]
    }, {
      path: ':user_id/leaves/ot/:id',
      name: _i18n.default.t('route.UserOTRecord'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/ot'))),
      meta: {
        title: 'UserOTRecord',
        icon: 'chart',
        roles: ['Admin', 'Operation Manager', 'Store Manager']
      },
      hidden: true
    }]
  },
  // stores
  {
    path: '/stores',
    name: _i18n.default.t('route.Stores'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/index'))),
    meta: {
      title: 'Schedule',
      icon: 'shop'
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditStore'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/edit'))),
      meta: {
        title: 'EditStore',
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: ':id/sales',
      name: _i18n.default.t('route.Sale'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/sales'))),
      meta: {
        title: 'Sale',
        icon: 'shopping',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true,
      children: [{
        path: 'upload',
        name: _i18n.default.t('route.SaleUpload'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/sale-upload'))),
        meta: {
          title: 'SaleUpload',
          icon: 'shopping',
          roles: ['Admin', 'Operation Manager']
        },
        hidden: true
      }]
    }, {
      path: ':id/products',
      name: _i18n.default.t('route.StoreProduct'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/products-index'))),
      meta: {
        title: 'StoreProduct',
        icon: 'shopping',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true,
      children: [{
        path: 'add',
        name: _i18n.default.t('route.AddStoreProduct'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/products-edit'))),
        meta: {
          title: 'AddStoreProduct',
          icon: 'shopping',
          roles: ['Admin', 'Operation Manager']
        },
        hidden: true
      }, {
        path: 'edit/:sProdId',
        name: _i18n.default.t('route.EditStoreProduct'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/products-edit'))),
        meta: {
          title: 'EditStoreProduct',
          icon: 'shopping',
          roles: ['Admin', 'Operation Manager']
        },
        hidden: true
      }, {
        path: ':sProdId/combo',
        name: _i18n.default.t('route.StoreProductCombo'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/products-combo-index'))),
        meta: {
          title: 'StoreProductCombo',
          icon: 'shopping',
          roles: ['Admin', 'Operation Manager']
        },
        hidden: true,
        children: [{
          path: 'add',
          name: _i18n.default.t('route.AddStoreProductCombo'),
          component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/products-combo-edit'))),
          meta: {
            title: 'AddStoreProductCombo',
            icon: 'shopping',
            roles: ['Admin', 'Operation Manager']
          },
          hidden: true
        }, {
          path: 'edit/:sProdRawMaterialId',
          name: _i18n.default.t('route.EditStoreProductCombo'),
          component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/products-combo-edit'))),
          meta: {
            title: 'EditStoreProductCombo',
            icon: 'shopping',
            roles: ['Admin', 'Operation Manager']
          },
          hidden: true
        }]
      }]
    }, {
      path: ':id/expenses',
      name: _i18n.default.t('route.StoreExpense'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/expenses-index'))),
      meta: {
        title: 'StoreExpense',
        icon: 'expense',
        roles: ['Admin']
      },
      hidden: true,
      children: [{
        path: 'add',
        name: _i18n.default.t('route.AddStoreExpense'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/expenses-edit'))),
        meta: {
          title: 'AddStoreExpense',
          icon: 'expense',
          roles: ['Admin']
        },
        hidden: true
      }, {
        path: 'edit/:sExpenseId',
        name: _i18n.default.t('route.EditStoreExpense'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/expenses-edit'))),
        meta: {
          title: 'EditStoreExpense',
          icon: 'expense',
          roles: ['Admin']
        },
        hidden: true
      }]
    }, {
      path: 'salaryreport',
      name: _i18n.default.t('route.SalaryReport'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/salaryreport'))),
      meta: {
        title: 'SalaryReport',
        icon: 'shopping'
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddStore'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/stores/edit'))),
      meta: {
        title: 'AddStore',
        icon: 'shopping',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true
    }, {
      path: 'roster/:id',
      name: _i18n.default.t('route.Roster'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/duties/roster'))),
      meta: {
        title: 'Roster',
        icon: 'peoples'
      },
      hidden: true
    }]
  },
  // duties
  {
    path: '/duties',
    name: _i18n.default.t('route.Payroll'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/duties/index'))),
    meta: {
      title: 'Payroll',
      icon: 'money'
    }
  },
  // attendance
  {
    path: '/attendances',
    name: _i18n.default.t('route.Attendances'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/attendances/index'))),
    meta: {
      title: 'Attendances',
      icon: 'skill'
    },
    children: [{
      path: 'add',
      name: _i18n.default.t('route.AddAttendance'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/attendances/edit'))),
      meta: {
        title: 'AddAttendance',
        icon: 'skill'
      },
      hidden: true
    }]
  },
  // leave
  {
    path: '/leaves',
    name: _i18n.default.t('route.LeaveSetting'),
    // i18n.t('route.Users'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/index'))),
    meta: {
      title: 'LeaveSetting',
      // i18n.t('route.Users'),
      icon: 'leave-type',
      roles: ['Admin', 'Operation Manager']
    },
    children: [{
      path: 'edit/:id',
      name: 'Leaves',
      // i18n.t('route.EditShift'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/edit'))),
      meta: {
        title: 'Leaves',
        // i18n.t('route.EditShift'),
        icon: 'skill',
        roles: ['Admin']
      },
      hidden: true
    }, {
      path: 'add',
      name: 'Leaves Add',
      // i18n.t('route.AddShift'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/edit'))),
      meta: {
        title: 'Leaves',
        // i18n.t('route.AddShift'),
        icon: 'skill',
        roles: ['Admin']
      },
      hidden: true
    }, {
      path: 'staff/:id',
      name: _i18n.default.t('route.RoleMapping'),
      // i18n.t('route.EditShift'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/staff'))),
      meta: {
        title: 'RoleMapping',
        // i18n.t('route.EditShift'),
        icon: 'skill',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true
    }, {
      path: 'approval',
      name: _i18n.default.t('route.LeaveApproval'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/approval'))),
      meta: {
        title: 'LeaveApproval',
        icon: 'skill'
      },
      hidden: true
    }]
  },
  // Approve
  {
    path: '/leaves/application',
    name: _i18n.default.t('route.LeaveApplication'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/application'))),
    meta: {
      title: 'LeaveApplication',
      icon: 'leave-submission',
      roles: ['Admin', 'Operation Manager']
    }
  },
  // shifts
  {
    path: '/shifts',
    name: _i18n.default.t('route.Shifts'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shifts/index'))),
    meta: {
      title: 'Shifts',
      icon: 'chart',
      roles: ['Admin', 'Operation Manager']
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditShift'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shifts/edit'))),
      meta: {
        title: 'EditShift',
        icon: 'education',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddShift'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/shifts/edit'))),
      meta: {
        title: 'AddShift',
        icon: 'education',
        roles: ['Admin', 'Operation Manager']
      },
      hidden: true
    }]
  },
  // Expense
  {
    path: '/expenses',
    name: _i18n.default.t('route.Expenses'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/expenses/index'))),
    meta: {
      title: 'Expenses',
      icon: 'expense',
      roles: ['Admin']
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditExpense'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/expenses/edit'))),
      meta: {
        title: 'EditExpense',
        icon: 'education',
        roles: ['Admin']
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddSExpense'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/expenses/edit'))),
      meta: {
        title: 'AddExpense',
        icon: 'education',
        roles: ['Admin']
      },
      hidden: true
    }]
  }]
},
// Procurement
{
  path: '/meterial-planning',
  component: _layout.default,
  redirect: 'noRedirect',
  name: _i18n.default.t('route.MaterialPlanning'),
  meta: {
    title: 'MaterialPlanning',
    icon: 'table',
    roles: ['Admin', 'Procurement Manager', 'Warehouse Manager']
  },
  children: [{
    path: '/suppliers',
    name: _i18n.default.t('route.Supplier'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/suppliers/index'))),
    meta: {
      title: 'Supplier',
      icon: 'tree',
      roles: ['Admin', 'Procurement Manager']
    },
    children: [{
      path: 'edit/:id',
      name: _i18n.default.t('route.EditSupplier'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/suppliers/edit'))),
      meta: {
        title: 'EditSupplier',
        icon: 'tree',
        roles: ['Admin', 'Procurement Manager']
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddSupplier'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/suppliers/edit'))),
      meta: {
        title: 'AddSupplier',
        icon: 'tree',
        roles: ['Admin', 'Procurement Manager']
      },
      hidden: true
    }]
  }, {
    path: '/raw-materials/print-packing/:id',
    name: _i18n.default.t('route.PrintRawMaterialPacking'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/rawMaterials/printPacking'))),
    meta: {
      title: 'PrintRawMaterialPacking',
      icon: 'table',
      roles: ['Admin', 'Procurement Manager', 'Store Manager', 'Warehouse Manager']
    },
    hidden: true
  }, {
    path: '/raw-materials',
    name: _i18n.default.t('route.RawMaterial'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/rawMaterials/index'))),
    meta: {
      title: 'RawMaterial',
      icon: 'table',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    },
    children: [{
      path: ':id/warehouses',
      name: 'route-warehouses',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/rawMaterials/Warehouses'))),
      meta: {
        title: 'Warehouses',
        icon: 'table',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }, {
      path: ':id/purchase-orders',
      name: 'route-purchase-orders-history',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/rawMaterials/PurchaseOrders'))),
      meta: {
        title: 'PurchaseOrderHistory',
        icon: 'table',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }, {
      path: 'edit/:id',
      name: _i18n.default.t('route.EditRawMaterial'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/rawMaterials/edit'))),
      meta: {
        title: 'EditRawMaterial',
        icon: 'table',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }, {
      path: 'add',
      name: _i18n.default.t('route.AddRawMaterial'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/rawMaterials/edit'))),
      meta: {
        title: 'AddRawMaterial',
        icon: 'table',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }]
  }, {
    path: '/raw-materials/:rawMaterialId/scheduled-price',
    name: _i18n.default.t('route.RawMaterialScheduledPrice'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/rawMaterials/scheduled-price-index'))),
    meta: {
      title: 'RawMaterialScheduledPrice',
      icon: 'table',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    },
    hidden: true
  }, {
    path: '/raw-materials/:rawMaterialId/scheduled-price/add',
    name: _i18n.default.t('route.AddRawMaterialScheduledPrice'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/rawMaterials/scheduled-price-edit'))),
    meta: {
      title: 'AddRawMaterialScheduledPrice',
      icon: 'table',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    },
    hidden: true
  },
  /* {
    path: '/purchase-requests',
    redirect: '/purchase-requests/confirmed',
    name: i18n.t('route.PurchaseRequests'),
    component: () => import('@/views/purchaseRequests/index'),
    meta: {
      title: 'PurchaseRequests',
      icon: 'message',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    }
  }, */
  {
    path: '/purchase-requests/(all|drafted|submitted|confirmed|completed)',
    name: _i18n.default.t('route.PurchaseRequests'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/purchaseRequests/index'))),
    meta: {
      title: 'PurchaseRequests',
      icon: 'message',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    },
    hidden: true,
    children: [{
      path: '/purchase-requests/add',
      name: _i18n.default.t('route.AddMessage'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/purchaseRequests/edit'))),
      meta: {
        title: 'AddPurchaseRequest',
        icon: 'message',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }]
  }, {
    // Warehouse PO Index
    path: '/purchase-orders/Kconfirmed',
    redirect: '/purchase-orders/confirmed',
    name: 'route.PurchaseOrderConfirmed',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/purchaseOrders/index'))),
    meta: {
      title: 'PurchaseOrders',
      icon: 'form',
      roles: ['Admin', 'Warehouse Manager', 'Procurement Manager']
    },
    children: [{
      path: '/purchase-requests/edit/:id',
      name: _i18n.default.t('route.EditMessage'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/purchaseRequests/edit'))),
      meta: {
        title: 'EditPurchaseRequest',
        icon: 'form',
        roles: ['Admin', 'Procurement Manager', 'Store Manager']
      },
      hidden: true
    }, {
      path: '/purchase-orders/edit/:id',
      name: _i18n.default.t('route.EditPurchaseOrder'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/purchaseOrders/edit'))),
      meta: {
        title: 'EditPurchaseOrder',
        icon: 'form'
      },
      hidden: true
    }, {
      path: '/purchase-orders/add',
      name: _i18n.default.t('route.AddPurchaseOrder'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/purchaseOrders/edit'))),
      meta: {
        title: 'AddPurchaseOrder',
        icon: 'form'
      },
      hidden: true
    }]
  },
  /* { // Other PO Index
    path: '/purchase-orders/Ksubmitted',
    redirect: '/purchase-orders/submitted',
    name: 'route.PurchaseOrderSubmitted',
    component: () => import('@/views/purchaseOrders/index'),
    meta: {
      title: 'PurchaseOrdersRequest',
      icon: 'message',
      roles: ['Admin', 'Procurement Manager', 'Store Manager']
    }
  }, */
  {
    path: '/purchase-orders/(submitted|drafted|confirmed|outstanding|completed|all)',
    name: _i18n.default.t('route.PurchaseOrders'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/purchaseOrders/index'))),
    meta: {
      title: 'PurchaseOrders',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/good-received-notes',
    redirect: '/good-received-notes/confirmed',
    name: _i18n.default.t('route.GoodReceived'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/goodReceivedNotes/index'))),
    meta: {
      title: 'GoodReceived',
      icon: 'delivery'
    },
    children: [{
      path: '/good-received-notes/edit/:id',
      name: _i18n.default.t('route.EditGoodReceived'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/goodReceivedNotes/edit'))),
      meta: {
        title: 'EditGoodReceived',
        icon: 'delivery'
      },
      hidden: true
    }, {
      path: '/good-received-notes/add',
      name: _i18n.default.t('route.AddGoodReceived'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/goodReceivedNotes/edit'))),
      meta: {
        title: 'AddGoodReceived',
        icon: 'delivery'
      },
      hidden: true
    }]
  }, {
    path: '/good-received-notes/(all|drafted|confirmed|completed)',
    name: 'route.GoodsReceived',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/goodReceivedNotes/index'))),
    meta: {
      title: 'GoodReceived',
      icon: 'delivery'
    },
    hidden: true
  }]
},
// inventory
{
  path: '/warehouses',
  redirect: 'noRedirect',
  component: _layout.default,
  name: _i18n.default.t('route.WarehouseManagement'),
  meta: {
    title: 'WarehouseManagement',
    icon: 'warehouse',
    roles: ['Admin', 'Operation Manager', 'Procurement Manager', 'Warehouse Manager']
  },
  children: [{
    path: '/warehouses',
    name: _i18n.default.t('route.Warehouses'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/index'))),
    meta: {
      title: 'Warehouses',
      icon: 'warehouse',
      roles: ['Admin', 'Operation Manager', 'Procurement Manager', 'Warehouse Manager']
    },
    children: [{
      path: ':id/areas',
      name: _i18n.default.t('route.WarehouseAreas'),
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/area-index'))),
      meta: {
        title: 'WarehouseAreas',
        icon: 'shopping'
      },
      hidden: true,
      children: [{
        path: '/warehouses/:id/area-add',
        name: 'WarehouseAreaAdd',
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/area-edit'))),
        meta: {
          title: 'WarehouseArea',
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: ':area_id/edit',
        name: _i18n.default.t('route.EditWarehouseArea'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/area-edit'))),
        meta: {
          title: 'EditWarehouseArea',
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: ':area_id/raw-materials',
        name: _i18n.default.t('route.WarehouseAreasRawMaterial'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/area-raw-material-index'))),
        meta: {
          title: 'WarehouseAreasRawMaterial',
          icon: 'shopping'
        },
        hidden: true,
        children: [{
          path: '/warehouses/:id/areas/:area_id/add-raw-material',
          name: 'WarehouseAreasRawMaterialAdd',
          component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/area-raw-material-edit'))),
          meta: {
            title: 'WarehouseAreasRawMaterial',
            icon: 'shopping'
          },
          hidden: true
        }]
      }, {
        /**
         * :id suppose warehouseId just for breadcrumb
         * has params.area_id but now just using query.warehouseAreaId
         * whenever has query.rawMaterialId supposed has 2 kinds, Warehouses.vue by warehouse, the other by WarehouseArea
         */
        path: ':area_id/records',
        name: _i18n.default.t('route.WarehouseRecords'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/record-index'))),
        meta: {
          title: 'WarehouseRecords',
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: ':area_id/(stock-in)',
        name: _i18n.default.t('route.StockIn'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/stock-in'))),
        meta: {
          title: 'StockIn',
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: ':area_id/(stock-out)',
        name: _i18n.default.t('route.StockOut'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/stock-in'))),
        meta: {
          title: 'StockOut',
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: ':area_id/(stock-take)',
        name: _i18n.default.t('route.StockTake'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/stock-in'))),
        meta: {
          title: 'StockOut',
          icon: 'shopping'
        },
        hidden: true
      }, {
        path: ':area_id/(stock-transit)',
        name: _i18n.default.t('route.StockTransit'),
        component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/stock-in'))),
        meta: {
          title: 'StockTransit',
          icon: 'shopping'
        },
        hidden: true
      }]
    }, {
      path: '/warehouse-areas/:areaId/raw-materials',
      name: 'WarehouseAreasRawMaterialRedirector',
      component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/warehouses/area-raw-material-redirector'))),
      meta: {
        title: 'WarehouseAreasRawMaterial',
        icon: 'shopping'
      },
      hidden: true
    } /*,
      {
       path: ':id/inventory-lv',
       name: i18n.t('route.WarehouseInventoryLv'),
       component: () => import('@/views/warehouses/inventory-lv'),
       meta: {
         title: 'WarehouseInventoryLv',
         icon: 'shopping'
       },
       hidden: true
      }*/]
  }]
},
// customer order
/* {
  path: '/order',
  redirect: 'noRedirect',
  component: Layout,
  name: i18n.t('route.Order'),
  meta: {
    title: 'Order',
    icon: 'shopping',
    roles: ['Admin', 'Store Manager', 'Floor Manager'],
    tenants: ['tastelab']
  },
  children: [
    {
      path: '/orders',
      name: i18n.t('route.Orders'),
      component: () => import('@/views/orders/index'),
      meta: {
        title: 'Orders',
        icon: 'shopping',
        tenants: ['tastelab']
      }
    },
    {
      path: '/orders/edit/:uuid',
      name: i18n.t('route.EditOrder'),
      component: () => import('@/views/orders/edit'),
      meta: {
        title: 'EditOrder',
        icon: 'shopping',
        tenants: ['tastelab']
      },
      hidden: true
    },
    {
      path: '/orders/edit/:uuid/:type',
      name: i18n.t('route.EditOrder') + ' Type',
      component: () => import('@/views/orders/edit'),
      meta: {
        title: 'EditOrder',
        icon: 'shopping',
        tenants: ['tastelab']
      },
      hidden: true
    },
    {
      path: '/orders/summary/:uuid',
      name: i18n.t('route.EditOrder') + ' Summary',
      component: () => import('@/views/orders/summary'),
      meta: {
        title: 'SummaryOrder',
        icon: 'shopping',
        tenants: ['tastelab']
      },
      hidden: true
    },
    {
      path: '/orders/summary/:uuid/:type',
      name: i18n.t('route.EditOrder') + ' Summary Type',
      component: () => import('@/views/orders/summary'),
      meta: {
        title: 'SummaryOrder',
        icon: 'shopping',
        tenants: ['tastelab']
      },
      hidden: true
    },
    {
      path: '/orders/add',
      name: i18n.t('route.AddStore') + ' Order',
      component: () => import('@/views/orders/edit'),
      meta: {
        title: 'EditOrder',
        icon: 'shopping'
      },
      hidden: true
    },
    {
      path: '/orders/add/:type',
      name: i18n.t('route.AddStore') + ' Order Type',
      component: () => import('@/views/orders/edit'),
      meta: {
        title: 'EditOrder',
        icon: 'shopping',
        tenants: ['tastelab']
      },
      hidden: true
    }
  ]
},*/
// delivery type
/* {
  path: '/delivery',
  redirect: 'noRedirect',
  component: Layout,
  name: i18n.t('route.DeliveryTypes'),
  meta: {
    title: 'DeliveryTypes',
    icon: 'meal',
    roles: ['Admin', 'Operation Manager'],
    tenants: ['tastelab']
  },
  children: [
    {
      path: '/deliverytype',
      name: i18n.t('route.DeliveryTypes') + ' List',
      component: () => import('@/views/deliverytype/index'),
      meta: {
        title: 'DeliveryTypes',
        icon: 'delivery',
        tenants: ['tastelab']
      }
    },
    {
      path: '/deliverytype/edit/:id',
      name: i18n.t('route.DeliveryTypes') + ' Edit',
      component: () => import('@/views/deliverytype/edit'),
      meta: {
        title: 'EditDeliveryTypes',
        icon: 'table',
        tenants: ['tastelab']
      },
      hidden: true
    },
    {
      path: '/deliverytype/add',
      name: i18n.t('route.DeliveryTypes') + ' Add',
      component: () => import('@/views/deliverytype/edit'),
      meta: {
        title: 'AddDeliveryTypes',
        icon: 'table',
        tenants: ['tastelab']
      },
      hidden: true
    }
  ]
},*/
// pos
/* {
  path: '/pos',
  redirect: 'noRedirect',
  component: Layout,
  name: i18n.t('route.POS'),
  meta: {
    title: 'POS',
    icon: 'meal',
    roles: ['Admin', 'Operation Manager', 'Store manager'],
    tenants: ['tastelab']
  },
  withStore: true,
  children: [
    {
      path: '/pos/modifier/home',
      name: i18n.t('route.Modifier'),
      component: EmptyLayout,
      redirect: '/pos/modifier',
      hidden: true,
      meta: {
        title: 'Modifier',
        icon: 'admin',
        tenants: ['tastelab']
      },
      children: [
        {
          path: '/pos/modifier',
          name: i18n.t('route.List') + ' List',
          component: () => import('@/views/pos/modifier'),
          meta: {
            title: 'List',
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/modifier/add',
          name: i18n.t('Modifier.Add') + ' Add',
          component: () => import('@/views/pos/modifier-edit'),
          meta: {
            title: i18n.t('Modifier.Add'),
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/modifier/edit/:id',
          name: i18n.t('Modifier.Edit'),
          component: () => import('@/views/pos/modifier-edit'),
          meta: {
            title: i18n.t('Modifier.Edit'),
            tenants: ['tastelab']
          },
          hidden: true
        }
      ]
    },
    {
      path: '/pos/item/home',
      name: i18n.t('route.Item'),
      component: EmptyLayout,
      meta: {
        title: 'Item',
        icon: 'admin',
        tenants: ['tastelab']
      },
      redirect: '/pos/item',
      children: [
        {
          path: '/pos/item',
          name: i18n.t('route.List'),
          component: () => import('@/views/pos/item'),
          meta: {
            title: 'List',
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/item/add',
          name: i18n.t('Item.Add'),
          component: () => import('@/views/pos/item-edit'),
          hidden: true,
          meta: {
            title: i18n.t('Item.Add'),
            tenants: ['tastelab']
          }
        },
        {
          path: '/pos/item/edit/:id',
          name: i18n.t('Item.Edit'),
          component: () => import('@/views/pos/item-edit'),
          hidden: true,
          meta: {
            title: i18n.t('Item.Edit'),
            tenants: ['tastelab']
          }
        }
      ]
    },
    {
      path: '/pos/product-type/home',
      name: i18n.t('route.ProductType'),
      component: EmptyLayout,
      redirect: '/pos/product-type',
      meta: {
        title: 'ProductType',
        icon: 'admin',
        tenants: ['tastelab']
      },
      children: [
        {
          path: '/pos/product-type',
          name: i18n.t('route.List') + ' Home',
          component: () => import('@/views/pos/product-type'),
          meta: {
            title: 'List',
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/product-type/add',
          name: i18n.t('ProductType.Add'),
          component: () => import('@/views/pos/product-type-edit'),
          meta: {
            title: i18n.t('ProductType.Add'),
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/product-type/edit/:id',
          name: i18n.t('ProductType.Edit'),
          component: () => import('@/views/pos/product-type-edit'),
          meta: {
            title: i18n.t('ProductType.Edit'),
            tenants: ['tastelab']
          },
          hidden: true
        }
      ]
    },
    {
      path: '/pos/sub-product-type/home',
      name: i18n.t('route.SubProductType'),
      component: EmptyLayout,
      redirect: '/pos/sub-product-type',
      meta: {
        title: 'SubProductType',
        icon: 'admin',
        tenants: ['tastelab']
      },
      children: [
        {
          path: '/pos/sub-product-type',
          name: i18n.t('route.List') + ' Sub Product Type',
          component: () => import('@/views/pos/sub-product-type'),
          meta: {
            title: 'List',
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/sub-product-type/add',
          name: i18n.t('SubProductType.Add'),
          component: () => import('@/views/pos/sub-product-type-edit'),
          meta: {
            title: i18n.t('SubProductType.Add'),
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/sub-product-type/edit/:id',
          name: i18n.t('SubProductType.Edit'),
          component: () => import('@/views/pos/sub-product-type-edit'),
          meta: {
            title: i18n.t('SubProductType.Edit'),
            tenants: ['tastelab']
          },
          hidden: true
        }
      ]
    },
    {
      path: '/pos/categories/home',
      name: i18n.t('route.Category') + ' Home',
      component: EmptyLayout,
      redirect: '/pos/categories',
      meta: {
        title: 'Category',
        icon: 'admin',
        tenants: ['tastelab']
      },
      children: [
        {
          path: '/pos/categories',
          name: i18n.t('route.List') + ' Category List',
          component: () => import('@/views/pos/category'),
          meta: {
            title: 'List',
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/category/add',
          name: i18n.t('route.Category'),
          component: () => import('@/views/pos/category-edit'),
          meta: {
            title: 'Category',
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/category/edit/:id',
          name: i18n.t('route.Category') + ' Edit',
          component: () => import('@/views/pos/category-edit'),
          meta: {
            title: 'Category',
            tenants: ['tastelab']
          },
          hidden: true
        }
      ]
    },
    {
      path: '/pos/kitchen/home',
      name: 'POSKitchen',
      component: EmptyLayout,
      redirect: '/pos/kitchen',
      meta: {
        title: 'Kitchen',
        icon: 'admin',
        tenants: ['tastelab']
      },
      children: [
        {
          path: '/pos/kitchen',
          name: 'KitchenList',
          component: () => import('@/views/pos/kitchen'),
          meta: {
            title: 'List',
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/kitchen/add',
          name: 'KitchenAdd',
          component: () => import('@/views/pos/kitchen-edit'),
          meta: {
            title: i18n.t('Supplier.Add'),
            tenants: ['tastelab']
          },
          hidden: true
        },
        {
          path: '/pos/kitchen/edit/:id',
          name: 'KitchenEdit',
          component: () => import('@/views/pos/kitchen-edit'),
          meta: {
            title: i18n.t('Supplier.Edit'),
            tenants: ['tastelab']
          },
          hidden: true
        }
      ]
    }
  ]
},*/

// CRM
{
  path: '/crm',
  component: _layout.default,
  name: 'CRM',
  meta: {
    title: 'CRM',
    icon: 'peoples',
    roles: ['Admin']
  },
  redirect: 'noRedirect',
  children: [
  // moomoo members
  {
    path: '/moomoomembers',
    name: _i18n.default.t('route.MOOMOOMembers'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/moomoomembers/index'))),
    meta: {
      title: 'MOOMOOMembers',
      icon: 'peoples',
      tenants: ['tastelab']
    }
  }, {
    path: '/moomoomembers/show/:id',
    name: _i18n.default.t('route.ShowMOOMOOMember'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/moomoomembers/show'))),
    meta: {
      title: 'ShowMOOMOOMember',
      icon: 'education',
      tenants: ['tastelab']
    },
    hidden: true
  }, {
    path: '/moomoomembers/point/:id',
    name: 'ShowMOOMOOMemberPoint',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/moomoomembers/point'))),
    meta: {
      title: 'ShowMOOMOOMember',
      icon: 'education',
      tenants: ['tastelab']
    },
    hidden: true
  },
  /*
  {
    path: '/moomoomembers/edit/:id',
    name: i18n.t('route.EditMOOMOOMember'),
    component: () => import('@/views/moomoomembers/edit'),
    meta: {
      title: 'EditMOOMOOMember',
      icon: 'education'
    },
    hidden: true
  },
  {
    path: '/moomoomembers/add',
    name: i18n.t('route.AddMOOMOOMember'),
    component: () => import('@/views/moomoomembers/edit'),
    meta: {
      title: 'AddMOOMOOMember',
      icon: 'education'
    },
    hidden: true
  }
  */
  // message
  {
    path: '/message',
    name: _i18n.default.t('route.Message'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/messages/index'))),
    meta: {
      title: 'Message',
      icon: 'message'
    }
  }, {
    path: '/messages/edit/:id',
    name: _i18n.default.t('route.EditMessage') + ' Edit',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/messages/edit'))),
    meta: {
      title: 'EditMessage',
      icon: 'message'
    },
    hidden: true
  }, {
    path: '/messages/add',
    name: _i18n.default.t('route.AddMessage') + ' Add',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/messages/edit'))),
    meta: {
      title: 'AddMessage',
      icon: 'message'
    },
    hidden: true
  }]
},
// cash expense
{
  path: '/cashExpense',
  redirect: 'noRedirect',
  component: _layout.default,
  name: 'Cash Expense Layout',
  meta: {
    title: 'CashExpense',
    icon: 'money',
    roles: ['Admin', 'Operation Manager', 'Store Manager', 'Floor Manager']
  },
  children: [{
    path: '/cash-expense',
    name: _i18n.default.t('route.CashExpense'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/cashExpense/index'))),
    meta: {
      title: 'CashExpenseList',
      icon: 'claim'
    }
  }, {
    path: '/cash-expense/edit/:id',
    name: _i18n.default.t('route.CashExpenseEdit'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/cashExpense/edit'))),
    meta: {
      title: 'CashExpenseEdit',
      icon: 'education'
    },
    hidden: true
  }, {
    path: '/cash-expense/add',
    name: _i18n.default.t('route.CashExpenseAdd'),
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/cashExpense/edit'))),
    meta: {
      title: 'CashExpenseAdd',
      icon: 'education'
    },
    hidden: true
  }, {
    path: '/cash-expense-list',
    name: 'Cash Expense List',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/cashExpense/list'))),
    meta: {
      title: 'CashExpenseReport',
      icon: 'claim'
    }
  }, {
    path: '/cash-expense/:category/:date_range',
    name: 'Cash Expense Category',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/cashExpense/index'))),
    meta: {
      title: 'CashExpenseList',
      icon: 'claim'
    },
    hidden: true
  }]
},
// Leaves
{
  path: '/leavesLayout',
  component: _layout.default,
  redirect: 'noRedirect',
  name: 'Leave Type',
  meta: {
    title: 'Leave',
    icon: 'chart',
    roles: ['Admin', 'Operation Manager']
  },
  hidden: true,
  children: [{
    path: '/leaves/summary',
    name: 'Leaves Summary',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/summary'))),
    meta: {
      title: 'Leave Summary',
      icon: 'chart',
      roles: ['Admin', 'Operation Manager']
    }
  }, {
    path: '/leaves/summary/:id',
    name: 'Leaves History',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/history'))),
    meta: {
      title: 'Leave Apply Record',
      icon: 'chart'
    },
    hidden: true
  }, {
    path: '/leaves/apply/add',
    name: 'Leave App',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/apply'))),
    meta: {
      title: 'Leave App',
      // i18n.t('route.LabourCost'),
      icon: 'chart'
    },
    hidden: true
  }, {
    path: '/leaves/apply/edit/:id',
    name: 'Edit Leave App',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/leaves/apply'))),
    meta: {
      title: 'Edit Leave App',
      // i18n.t('route.LabourCost'),
      icon: 'chart'
    },
    hidden: true
  }]
},
// Reports
{
  path: '/reports',
  component: _layout.default,
  redirect: 'noRedirect',
  name: _i18n.default.t('route.Report'),
  meta: {
    title: 'Report',
    icon: 'chart',
    roles: ['Admin', 'Operation Manager']
  },
  children: [{
    path: '/reports/sales-vs-labour-cost',
    name: 'sales-vs-labour-cost',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/reports/sales-vs-labour-cost'))),
    meta: {
      title: 'LabourCost',
      icon: 'chart'
    }
  }, {
    path: '/reports/staff-attendance',
    name: 'staff-attendance',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/reports/staff-attendance'))),
    meta: {
      title: 'StaffAttendance',
      icon: 'chart'
    }
  }, {
    path: '/reports/productivity',
    name: 'productivity',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/reports/productivity'))),
    meta: {
      title: 'Productivity',
      icon: 'chart'
    }
  }, {
    path: '/reports/cogs',
    name: 'cogs',
    component: () => Promise.resolve().then(() => _interopRequireWildcard(require('@/views/reports/cogs'))),
    meta: {
      title: 'COGS',
      icon: 'chart'
    }
  }]
},
/* {
  path: '/',
  redirect: '/dashboard', // this will be replaced in permission.js
  component: Layout,
  name: 'Default Route'
}, */

// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;
const createRouter = () => new _vueRouter.default({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;