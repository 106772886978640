"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRefreshToken = getRefreshToken;
exports.getToken = getToken;
exports.removeRefreshToken = removeRefreshToken;
exports.removeToken = removeToken;
exports.setRefreshToken = setRefreshToken;
exports.setToken = setToken;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const TokenKey = `vue_admin_template_token${process.env.NODE_ENV === 'development' ? '_dev' : ''}`;
const RefreshTokenKey = `remember_me${process.env.NODE_ENV === 'development' ? '_dev' : ''}`;
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function getRefreshToken() {
  return _jsCookie.default.get(RefreshTokenKey); // expires in days
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token, {
    expires: 365,
    path: '/'
  });
}
function setRefreshToken(token) {
  return _jsCookie.default.set(RefreshTokenKey, token, {
    expires: 365,
    path: '/'
  });
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function removeRefreshToken() {
  return _jsCookie.default.remove(RefreshTokenKey);
}