"use strict";

var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _i18n = _interopRequireDefault(require("@/i18n"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhTW = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-TW"));
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("@/icons");
require("@/permission");
var _registerServiceWorker = _interopRequireDefault(require("./registerServiceWorker"));
var _vueOffline = _interopRequireDefault(require("vue-offline"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// A modern alternative to CSS resets

// global css

// icon
// permission control

if ( /* window.self === window.top && */process.env.VUE_APP_SENTRY_URL && `${process.env.VUE_APP_SENTRY_URL}` !== 'undefined' && `${process.env.VUE_APP_SENTRY_URL}` !== '' && `${process.env.VUE_APP_SENTRY_DSN}` !== '' && !/Daum|Headless|baiduboxapp| BW\/\d+\.\d+| PTST\/\d+\.\d+/.test(navigator.userAgent)) {
  const Sentry = require('@sentry/vue');
  // let {BrowserTracing} = require("@sentry/tracing");
  const {
    CaptureConsole: CaptureConsoleIntegration,
    ExtraErrorData
  } = require('@sentry/integrations');
  let logs = [/* 'info', */'warn', 'error']; // , 'debug'
  if (`${process.env.ENV}` === 'production') {
    logs = ['warn', 'error', 'debug'];
  }
  Sentry.init({
    Vue: _vue.default,
    release: 'erp-' + `${process.env.VUE_APP_BRANCHNAME}`,
    environment: `${process.env.ENV}`,
    dsn: `${process.env.VUE_APP_SENTRY_URL}`,
    ignoreErrors: [
    // 'undefined',
    /_AutofillCallbackHandler/, /extension:\/\//,
    // /\.\/src\/components\//,
    /\/C:\/Users\//, /quill Overwriting/],
    integrations: [new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(_router.default)
    }), new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: logs
    }), new ExtraErrorData({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 5
    }), new Sentry.Replay()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.4,
    replaysOnErrorSampleRate: 0.50,
    replaysSessionSampleRate: 0
  });
}

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
if (process.env.NODE_ENV === 'test') {
  const {
    mockXHR
  } = require('../mock');
  mockXHR();
}
const errorHandler = onChunkFailed => {
  window.addEventListener('error', event => {
    if (event.target.tagName === 'SCRIPT' || event.target.tagName === 'LINK') {
      // Check if the error is related to a failed chunk load
      const chunkFailed = event.target.src.includes('.chunk.') || /\/static\/js\/\d+\.[a-f0-9]+\.js^/.test(event.target.src);
      if (chunkFailed) {
        // Force the service worker to refresh

        onChunkFailed();
      }
    } else if (event.target instanceof IDBOpenDBRequest) {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          registrations.forEach(registration => {
            registration.unregister();
          });
        });
      }
      alert('Error opening IndexedDB: ' + event.target.error + '\nYou may need to cleanup and restart this browser');
    }
  });
};
if (navigator.serviceWorker.controller && navigator.serviceWorker.controller.state === 'activated') {
  // The service worker is already activated
  // console.log('Workbox service worker is already activated');
  // Perform any additional logic here

  errorHandler(() => {
    _registerServiceWorker.default.messageSW({
      type: 'SKIP_WAITING'
    });
    setTimeout(() => window.location.reload(), 5500);
  });
} else {
  const onActivated = () => {
    // The service worker has been activated
    // console.log('Workbox service worker is activated');
    // Perform any additional logic here
    errorHandler(() => {
      _registerServiceWorker.default.messageSW({
        type: 'SKIP_WAITING'
      });
      setTimeout(() => window.location.reload(), 5500);
    });
    _registerServiceWorker.default.removeEventListener('activated', onActivated);
  };
  _registerServiceWorker.default.addEventListener('activated', onActivated);
  const onRegistrationerror = () => {
    // The service worker has been activated
    // console.log('Workbox service worker is activated');
    // Perform any additional logic here
    errorHandler(() => {
      window.location.reload();
    });
    _registerServiceWorker.default.removeEventListener('registrationerror', onRegistrationerror);
  };
  _registerServiceWorker.default.addEventListener('registrationerror', onRegistrationerror);
}
_vue.default.prototype.$workbox = _registerServiceWorker.default;

// set ElementUI lang to EN
const lang = {
  en: _en.default,
  zh: _zhTW.default
};
_vue.default.use(_elementUi.default, {
  locale: lang[_i18n.default.locale]
});
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)
_vue.default.use(_vueOffline.default);
_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _i18n.default,
  render: h => h(_App.default)
});