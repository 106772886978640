"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _getters = _interopRequireDefault(require("./getters"));
var _app = _interopRequireDefault(require("./modules/app"));
var _permission = _interopRequireDefault(require("./modules/permission"));
var _settings = _interopRequireDefault(require("./modules/settings"));
var _user = _interopRequireDefault(require("./modules/user"));
var _lesson = _interopRequireDefault(require("./modules/lesson"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
_vue.default.use(_vuex.default);
const store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    permission: _permission.default,
    settings: _settings.default,
    user: _user.default,
    lesson: _lesson.default
  },
  getters: _getters.default
});
var _default = store;
exports.default = _default;